import React from "react";
import styled, { css } from "styled-components";

import { Typography } from "theme";

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 24px;
    height: 56px;
    width: ${({ width }) => width ?? "auto"};
    border-radius: ${({ theme }) => theme.radius.sm};
    color: ${({ color }) => color};

    background-color: ${({ theme }) => theme.color.primary1};
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: ${({ theme }) => theme.shadow.primary};
    :hover {
        cursor: pointer;
        opacity: 0.9;
    }

    ${({ variant }) =>
        variant === "basic" &&
        css`
            background-color: ${({ theme }) => theme.color.bg2};
            color: ${({ theme }) => theme.color.text1};
            border: 1px solid rgba(255, 255, 255, 0.15);
            box-shadow: none;
        `}
    ${({ variant }) =>
        variant === "secondary" &&
        css`
            background-color: transparent;
            color: ${({ theme }) => theme.color.primary1};
            border: 2px solid ${({ theme }) => theme.color.primary1};
            box-shadow: none;
        `}
    ${({ variant }) =>
        variant === "destructive" &&
        css`
            background-color: ${({ theme }) => theme.color.statusError};
            box-shadow: 0px 0px 8px #fc0c4a, 0px 4px 20px rgba(0, 0, 0, 0.25);
        `}
    ${({ variant }) =>
        variant === "disabled" &&
        css`
            background-color: ${({ theme }) => theme.color.bg3};
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            :hover {
                opacity: 1;
                cursor: default;
            }
        `}
    ${({ variant }) =>
        variant === "wrapper" &&
        css`
            background-color: transparent;
            box-shadow: none;
            border: none;
        `}
`;

export default function Button({ variant, onClick, width, children }) {
    return (
        <StyledButton variant={variant} onClick={onClick} width={width}>
            <Typography.heading color="inherit">{children}</Typography.heading>
        </StyledButton>
    );
}
