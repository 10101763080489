import { useGlobalStore } from "data/store";
import { getPriceAlerts } from "data/requests";
import { useAccessToken } from "utils/hooks/useAccessToken";
import { STORE_KEYS } from "constants.js";
import { logout } from "utils/logout";
import {  useHistory } from "react-router-dom";

export function usePriceAlerts(shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const accessToken = useAccessToken();
    const key = STORE_KEYS.PRICE_ALERTS;
    const priceAlertData = store[key];
    const history = useHistory();

    async function checkForData() {
        // Fetch the data if it hasn't been fetched already:
        if ((!priceAlertData || shouldRefresh) && accessToken) {
            const data = await getPriceAlerts(accessToken);
            updateStore(key, data);
        }
    }

        try {
          checkForData();
        } catch {
          logout();
          history.go(0);
        }


    return priceAlertData;
}
