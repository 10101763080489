import { checkResponse } from "utils";

export async function getAccount(accessToken) {
    //console.log("Calling getAccount");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/me`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${accessToken}`,
            },
        }
    );
    if (await checkResponse(response)) {
        const account = await response.json();
        return account;
    } else {
        return null;
    }
}
