import React from "react";
import styled, { useTheme } from "styled-components";

import { Typography } from "theme";
import { ResponsiveRow } from "components/Row";
import Column from "components/Column";
import { ExternalLink } from "components/shared";
import { URLS } from "constants.js";

const StyledFooter = styled.div`
    display: flex;
    height: 72px;
    width: 100%;
    align-items: center;
`;

export default function Footer() {
    const theme = useTheme();
    return (
        <StyledFooter>
            <Column>
                <ResponsiveRow justify="stretch">
                    <Typography.caption color={theme.color.text2}>
                        © 2021 Stake Signal
                    </Typography.caption>
                    <Typography.caption color={theme.color.text2}>
                        <ExternalLink href={URLS.COMPOUND_GRANTS}>
                            Compound Grants Batch 3
                        </ExternalLink>
                    </Typography.caption>
                </ResponsiveRow>
            </Column>
        </StyledFooter>
    );
}
