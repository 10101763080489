import React, { useState } from "react";
import { useTheme } from "styled-components";

import Modal from "components/Modal";
import { Typography } from "theme";
import Card from "components/Card";
import Column from "components/Column";
import Row from "components/Row";
import { Logo } from "components/shared";
import Button from "components/Button";
import TextInput from "components/TextInput";
import lockSvg from "assets/lock.svg";

/**
 *
 * @prop bool isOpen: if the modal is open or not
 * @prop callback submitEmail(string email): callback to return the submitted email to
 * @prop callback modalClosed: callback to give the close action to so caller can close modal
 *
 * @returns
 */
export default function EmailModal({ isOpen, submitEmail, modalClosed, formInvalid}) {
    const theme = useTheme();
    const [newEmail, setNewEmail] = useState(null);


    async function handleChangeEmail(email){
        setNewEmail(email);
    }

    return (
        <Modal isOpen={isOpen} modelClosed={modalClosed}>
            <Card>
                <Column align="flex-start">
                    <Typography.displayS>
                        Connect your email
                    </Typography.displayS>
                    <Typography.caption>
                        Connect your email to receive all your alert
                        notificaitons in your inbox.
                    </Typography.caption>
                        <Row>
                        <TextInput
                            type="text"
                            value={newEmail ?? ""}
                            placeholder="Enter email here..."
                            onChange={(event) =>
                                handleChangeEmail(event.target.value)
                            }
                        />
                        </Row>
                        { formInvalid &&
      <Typography.caption>  There is a problem with the form information </Typography.caption> }
                    <Card height="56px" variant="secondary">
                        <Logo src={lockSvg} />
                        <Typography.caption color={theme.color.text2}>
                            We only use your email address to deliver alerts. We
                            don’t share it, we don’t sell it and we don’t use
                            it.
                        </Typography.caption>
                    </Card>
                    <Button
                        width="100%"
                        variant="secondary"
                        onClick={() => {
                            submitEmail(newEmail);
                            setNewEmail(null);
                        }}
                    >
                        Connect email
                    </Button>
                </Column>
            </Card>
        </Modal>
    );
}
