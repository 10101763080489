export function shortAddress(address) {
    if (address === null || address === undefined) {
        return "";
    }

    const len = address.length;
    if (len < 12) return address;
    if (len < 12) {
        return address;
    } else {
        return address.slice(0, 6) + "..." + address.slice(len - 3, len);
    }
}

export function formatNumber(number, unit, decimals = null) {
    const K = 1000;
    const M = 1000000;
    const B = 1000000000;
    const T = 1000000000000;
    let defaultDecimals = 2;

    let postFix = "";
    let unitPostfix = false;
    let formattedNum = number;

    //console.log(formattedNum);

    if (number === null || number === undefined) {
        return "--";
    }

    // If it is represented as a sting, convert to number first
    if (typeof number === "string") {
        formattedNum = parseFloat(formattedNum);
        if (isNaN(formattedNum)) {
            return number; // It isn't a number
        }
    }

    // Multiply by 100, and set so unit is postfixed
    if (unit === "%") {
        formattedNum *= 100;
        unitPostfix = true;
    }

    // Converting to digestable format
    if (formattedNum > T) {
        formattedNum /= T;
        postFix = "T";
    } else if (formattedNum > B) {
        formattedNum /= B;
        postFix = "B";
    } else if (formattedNum > M) {
        formattedNum /= M;
        postFix = "M";
    } else if (formattedNum > K) {
        formattedNum /= K;
        postFix = "K";
    }

    // If its an interger, flag to rount to whole number
    if (formattedNum % 1 === 0) {
        defaultDecimals = 0;
    }

    // Applying configs
    if (decimals !== null) {
        formattedNum = formattedNum.toFixed(decimals).toString();
    } else {
        formattedNum = formattedNum.toFixed(defaultDecimals).toString();
    }
    formattedNum += postFix;
    formattedNum = unit
        ? unitPostfix
            ? formattedNum + unit
            : unit + formattedNum
        : formattedNum;

    return formattedNum;
}

/**
 *
 * @param {*} response: response object from http fetch
 * @returns bool: true if response is successful, false otherwise
 */
export async function checkResponse(response) {
    if (!response.ok) {
        const error = await response.text();
        console.log("Error:" + error);
        return false;
    }

    return true;
}
