import React, { useRef, useEffect } from "react";
import { useTheme } from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
//import { BannerAlert } from 'components/Alert';
import { Typography } from "theme";
import Card from "components/Card";
import Column from "components/Column";
import DeleteModal from "components/Delete";
import Button from "components/Button";
import Row from "components/Row";
import { SectionTitle, InternalLink,  ExternalLink, Logo } from "components/shared";
import {  COINS, PAGE_PATHS } from "constants.js";
import healthSvg from "assets/health.svg";
import { URLS } from "constants.js";
import warningSvg from "assets/warning.svg";
import styled from "styled-components";

import { useHealthAlerts, usePriceAlerts, useAccount, useTokens, useContact } from "data/hooks";
import { removeHealthAlert, removePriceAlert } from "data/requests";
import { useAccessToken, useIsLoggedIn } from "utils/hooks";
import { logout } from "utils/logout";



const RightText = styled.p`
  text-align: right;
  justify-content: flex-end;
  family: "Inter";
  fontSize: 16;
  fontWeight: 400;
  lineHeight: 24;
  color: "text1";
`;

export default function Home({ forceUpdateCallback }) {
    const theme = useTheme();
    const isLoggedIn = useIsLoggedIn();
    const accessToken = useAccessToken();
    const account = useAccount();
    const contact = useContact();
    const history = useHistory();
    const coinPrices = useTokens();
    // Refresh these only on the first render (won't change after rendered)
    const shouldRefresh = useRef(true); // Update on first render
    const healthAlerts = useHealthAlerts(shouldRefresh.current);
    const priceAlerts = usePriceAlerts(shouldRefresh.current);

    function errorLogout() {
      logout();
      history.go(0);
    }

    useEffect(() => {
        shouldRefresh.current = false;
    });

    if (!isLoggedIn) {
        return <Redirect to={PAGE_PATHS.LOGIN} />;
    }

    async function handleDeleteHealth(alert_id, access_token){
      await removeHealthAlert(alert_id, accessToken);
      history.go(0);
    }
    async function handleDeletePrice(alert_id, access_token){
      await removePriceAlert(alert_id, accessToken);
      history.go(0);
    }

    //console.log(healthAlerts);
    //console.log(priceAlerts);
    //console.log(account);
    // Wait for these to load
    if (!healthAlerts && !priceAlerts) {
        return (
          <Column gap={theme.spacing.lg}>
          <SectionTitle>Account Overview</SectionTitle>
              <Card p>
                  <Column align="left" gap={theme.spacing.sm}>
                      <Typography.body color={theme.color.text2}>

                          Compound Borrow Limit Used:
                  </Typography.body>
                      <Typography.body>
                        {account && (account.comp_health > 0)? Math.round( 1 / account.comp_health * 100 ) : "0"}%
                      </Typography.body>
                  </Column>
              </Card>
              <SectionTitle>Your Alerts</SectionTitle>
              <Card padding={theme.spacing.xl + " 0"}>
                  <Column gap={theme.spacing.sm}>
                      <Typography.displayS>
                          Loading Alerts
                      </Typography.displayS>
                  </ Column>
              </Card>
          </Column>);
    }
      const healthAlertRows  = (healthAlerts && healthAlerts.length > 0 ? ( healthAlerts.map((alert) => (
      <Row key={alert.id}>
        <Logo src={healthSvg} size="30px" />
        <Column align="left">
          <Typography.displayS>
          {  alert.email_notification_frequency ? (alert.phone_notification_frequency ? " Email & Text" : " Email ") : (alert.phone_notification_frequency ? " Text" : " ")}
              me when borrow limit goes above {100 * alert.account_health_threshold}%
          </Typography.displayS>
        </Column>
        <Column align="right">
          <DeleteModal  handleAction={() => handleDeleteHealth(alert.id, accessToken)} />
        </Column>
      </Row>
      ))) : null )

      const priceAlertRows  = ( priceAlerts &&
      priceAlerts.length > 0 ? ( priceAlerts.map((alert) => (
          <Row key={alert.id}>
            <Logo src={COINS.filter(t => t.name.toUpperCase() === alert.token_name.toUpperCase())[0]?.imgSrc }  size="30px" />
            <Column align="left">
            <Typography.displayS>
              {  alert.email_notification_frequency ? (alert.phone_notification_frequency ? " Email & Text" : " Email ") : (alert.phone_notification_frequency ? " Text" : " ")}
    when {alert.token_name.toUpperCase()} { alert.sign == -1 ? " is below " : " is above " }
                 ${alert.trigger_price}
                   </Typography.displayS>
                                     <Typography.body color={theme.color.text2}>
                  (Currently ${ coinPrices ? coinPrices.filter((obj) => obj.name === alert.token_name.toUpperCase())[0]?.current_price_usd : null })
              </Typography.body>
            </Column>
            <Column align="right">
              <DeleteModal handleAction={() => handleDeletePrice(alert.id, accessToken) } />
            </Column>
          </Row>
      ))) : null)

      const noAlerts =
            healthAlerts &&
            healthAlerts.length === 0 &&
            priceAlerts &&
            priceAlerts.length === 0;

    return (<>
      <Column gap={theme.spacing.lg}>

          <Card variant="question">
              <Column align="left" gap={theme.spacing.sm}>
                  <Typography.heading>
                      What is Stake Signal?
                  </Typography.heading>
                  <Typography.body>
                      Stake Signal allows you to receive customized alerts
                      about potential liquidation threats to your account on the Compound
                      protocol. You can opt-in to receive notifications via
                      Email or SMS.
                  </Typography.body>
                  <Row>
                      <Logo src={warningSvg} />
                      <Typography.body color={theme.color.statusWarning}>
                          This is an alpha release of Stake Signal supported
                          by a{" "}
                          <ExternalLink
                              href={URLS.COMPOUND_GRANTS}
                              variant="warning"
                          >
                              Compound Grant.
                          </ExternalLink> Use of this website is for informational purposes only. This website does not guarantee safety from losses, liquidations, or other risks.
                      </Typography.body>
                  </Row>
              </Column>
          </Card>
          <SectionTitle>Account Overview</SectionTitle>
              <Card p>
                <Column align="left" gap={theme.spacing.sm}>

                    <Typography.body color={theme.color.text2}>

                        Compound Borrow Limit Used:
                </Typography.body>
                    <Typography.body>
                      {account && (account.comp_health > 0)? Math.round( 1 / account.comp_health * 100 ) : "0"}%
                    </Typography.body>
                </Column>
            </Card>
            <SectionTitle>Your Alerts</SectionTitle>
            {noAlerts ? (
                <Card padding={theme.spacing.xl + " 0"}>
                    <Column gap={theme.spacing.sm}>
                        <Typography.displayS>
                            You don't have any alerts yet
                        </Typography.displayS>
                        <Typography.body color={theme.color.text2}>
                            This is where you can add and manage your custom
                            alerts.
                        </Typography.body>
                        <InternalLink to={PAGE_PATHS.CHOOSE_ALERT}>
                            <Button>Create Alert</Button>
                        </InternalLink>
                    </Column>
                </Card>
            ) : (
                <Card p>
                    <Column >
                        <Row>
                    <Column align="left">
                      <Typography.body>
                      Alert Rule
                      </Typography.body>
                    </Column>
                    <Column align="right"
                    justify-content="flex-end">
                      <Typography.body>
                    <RightText>
                    Delete</RightText>
                    </Typography.body>
                  </Column>

                      </Row>
                        {healthAlertRows}
                        {priceAlertRows}
                    </Column>
                </Card>
            )}
        </Column>
        </>
    );
}
