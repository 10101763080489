import Web3 from "web3";

//import { useContact } from "data/hooks";
import { checkResponse } from "utils";

import { LS_KEY } from "constants.js";

let web3 = undefined; // Will hold the web3 instance

async function authenticate(crypto_address, signature) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
        body: JSON.stringify({ crypto_address, signature }),
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
    });

    if (await checkResponse(response)) {
        const auth = await response.json();
        return auth.accessToken;
    } else {
        return null;
    }
}

async function signMessage(crypto_address, nonce) {
    try {
        const signature = await web3.eth.personal.sign(
            `Sign this message to log into Stake Signal and manage your Decentralized Finance Alerts. Your signature includes this unique number to authenticate your identity: ${nonce}`,
            crypto_address,
            "" // MetaMask will ignore the password argument here
        );

        return signature;
    } catch (err) {
        throw new Error("You need to sign the message to be able to log in.");
    }
}

async function signup(crypto_address) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/new`,
        {
            body: JSON.stringify({
                blockchain_name: "eth",
                crypto_address: crypto_address,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        }
    );
    console.log(response);

    await checkResponse(response);

    console.log(response);
}

export async function login() {
    console.log("Calling login");

    // Check if MetaMask is installed
    if (!window.ethereum) {
        window.alert("Please install MetaMask first.");
        return false;
    }

    if (!web3) {
        try {
            // Request account access if needed
            await window.ethereum.enable();

            // We don't know window.web3 version, so we use our own instance of Web3
            // with the injected provider given by MetaMask
            web3 = new Web3(window.ethereum);
        } catch (error) {
            window.alert("You need to allow MetaMask.");
            return false;
        }
    }

    const coinbase = await web3.eth.getCoinbase();
    if (!coinbase) {
        window.alert("Please activate MetaMask first.");
        return false;
    }

    const crypto_address = coinbase.toLowerCase();

    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/eth/${crypto_address}`
    );

    console.log(response);
    if (!(await checkResponse(response))) {
        console.log("Bad");
        return false;
    }

    let account = await response.json();

    // Empty, account doesn't exist yet
    if (Object.keys(account).length === 0) {
        console.log("doesn't exist yet");
        await signup(crypto_address);

        // TODO: if you make the signup call return the account, you don't need this additionaly request.
        // Here is where the bug is when initially signing up, this solves it, but its hacky
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/accounts/eth/${crypto_address}`
        );
        await checkResponse(response);
        account = await response.json();
    }

    // Popup MetaMask confirmation modal to sign message
    const signature = await signMessage(crypto_address, account.nonce);

    // Send signature to backend on the /auth route
    const accessToken = await authenticate(crypto_address, signature);

    // Save access token in local storage
    localStorage.setItem(LS_KEY, JSON.stringify(accessToken));

    //force refresh of account data
    //const contact = await useContact(true);
}
