import React, {  useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";

import { Typography } from "theme";
import Row from "components/Row";
import Button from "components/Button";
import { InternalLink, ProgressMarker, Logo } from "components/shared";
import { shortAddress } from "utils";
import { PAGE_PATHS } from "constants.js";
import dotSvg from "assets/dot.svg";

import stakeSignalLogo from "assets/StakeSignalLogo.svg"
import { login } from "data/requests";
import { logout } from "utils/logout";
import { useIsLoggedIn } from "utils/hooks";
import { useAccount, useContact } from "data/hooks";
import { useGlobalStore } from "data/store";
import { BannerAlert } from 'components/Alert';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledHeader = styled.div`
    display: flex;
    flex-wrap: wrap ;
    height: 88px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.bg0};
    align-items: center;
    padding: ${({ theme }) => theme.spacing.md + " " + theme.spacing.xxl};
`;

const CenterRow = styled.div`
    z-index: 1;
`;

const RightRow = styled.div`
    z-index: 10;
`;


export default function Header({ forceUpdateCallback }) {
    const  { clearStore } = useGlobalStore();
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const theme = useTheme();
    const contact = useContact();
    const account = useAccount();
    const isLoggedIn = useIsLoggedIn();

    const currentPath = location.pathname;

    async function handleLogin() {
        await login();
        forceUpdateCallback();
    }

    function handleLogout() {
        logout();
        //clearStore();
        setOpen(false);
        forceUpdateCallback();
    }

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

    const centerElement = useMemo(() => {
        if (
            currentPath === PAGE_PATHS.HOME ||
            currentPath === PAGE_PATHS.SETTINGS
        ) {
            return (
                <Row>
                <CenterRow>
                    <Row justify="center">
                    <Typography.displayS>
                        <InternalLink
                            to={PAGE_PATHS.HOME}
                            active={currentPath === PAGE_PATHS.HOME}
                        >
                            Home
                        </InternalLink>
                    </Typography.displayS>
                    <Typography.displayS>
                        <InternalLink
                            to={PAGE_PATHS.SETTINGS}
                            active={currentPath === PAGE_PATHS.SETTINGS}
                        >
                            Settings
                        </InternalLink>
                    </Typography.displayS>
                    </Row>
                    </CenterRow>
                </Row>
            );
        } else if (currentPath !== PAGE_PATHS.LOGIN) {
            // Choose alert, create health and price alert pages
            return (
        <Row>
            <CenterRow>
                <Row gap={theme.spacing.xs} justify="center">
                    <ProgressMarker active={true} />
                    <ProgressMarker
                        active={currentPath !== PAGE_PATHS.CHOOSE_ALERT}
                    />
                </Row>
                </CenterRow>
            </Row>
            );
        } else {
            // Login
            return null;
        }
    }, [currentPath, theme]);

    return (<>
        <StyledHeader>
            <Row justify="space-between">
                <Row>
                    <InternalLink to={PAGE_PATHS.HOME}>
                    <Logo src={stakeSignalLogo}   size="50px" />
                    </InternalLink>
                </Row>
                {centerElement}
                    {isLoggedIn ? (
                        <RightRow>

                        <Row justify="flex-end">{currentPath === PAGE_PATHS.HOME ||
                        currentPath === PAGE_PATHS.SETTINGS ||
                        currentPath === PAGE_PATHS.LOGIN ? (
                          <>
                            <InternalLink to={PAGE_PATHS.CHOOSE_ALERT}>
                                <Button   width={60}>Create Alert</Button>
                            </InternalLink>
                            </>
                        ) : null}
                            <Button variant="basic" onClick={handleClickOpen}  width={60}>
                                <Row gap={0}>
                                    <Logo src={dotSvg} />
                                    {shortAddress(account?.crypto_address)}
                                </Row>
                            </Button>

                          </Row>
                        </RightRow>
                    ) : (
                    <Row justify="flex-end">
                        <Button variant="secondary" onClick={handleLogin}  width={60}>
                            Connect Wallet
                        </Button>
                    </Row>
                    )}
            </Row>
        </StyledHeader>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to logout?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                No
              </Button>
              <Button onClick={handleLogout} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          { contact && contact.email_redacted && contact.validated_email != 1 ?
          <BannerAlert severity="warning">Confirm your email to receive alerts: Please check your email and follow the link to verify your email address.</BannerAlert>  : "" }
          </>
    );
}
