import { useGlobalStore } from "data/store";
import { getContact } from "data/requests";
import { useAccessToken } from "utils/hooks/useAccessToken";
import { STORE_KEYS } from "constants.js";
import { logout } from "utils/logout";
import {  useHistory } from "react-router-dom";

export function useContact(shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const accessToken = useAccessToken();
    const key = STORE_KEYS.CONTACT;
    const contactData = store[key];
    const history = useHistory();

    async function checkForData() {
        // Fetch the data if it hasn't been fetched already:
        if ((!contactData || shouldRefresh) && accessToken) {
            const data = await getContact(accessToken);
            updateStore(key, data);
        }
    }

    try {
      checkForData();
    } catch {
      logout();
      history.go(0);
    }

    return contactData;
}

export function ClearContact() {
    const [store, { updateStore }] = useGlobalStore();
    const key = STORE_KEYS.CONTACT;
    updateStore(key, null);

}
