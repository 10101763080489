import React, { useState, useRef } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";

import { Typography } from "theme";
import Card, { InfoCard } from "components/Card";
import Row from "components/Row";
import Column from "components/Column";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { PageTitle, BackNavigator, Break } from "components/shared";
import Button from "components/Button";
import { PAGE_PATHS, BORROW_LIMIT_TARGETS } from "constants.js";
import EmailModal from "components/Modal/EmailModal";
import healthSvg from "assets/health.svg";

import { registerEmail } from "data/requests";
import { login, createHealthAlert } from "data/requests";
import { useContact } from "data/hooks";
import { useAccessToken, useIsLoggedIn } from "utils/hooks";

export default function CreateHealthAlert({ forceUpdateCallback }) {
    const shouldRefresh = useRef(false);
    const theme = useTheme();
    const isLoggedIn = useIsLoggedIn();
    const accessToken = useAccessToken();
    const contact = useContact();
    const history = useHistory();
    const [limitThreshold, setLimitThreshold] = useState(
        BORROW_LIMIT_TARGETS[0]
    );
    const [alertMessage, setAlertMessage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);

    const contactMethodConnected = contact && contact.email_redacted !== null;

        function validate_email(email){
          const re = /[^@]+@[^@]+\.[^@]+/;
          return re.test(email);
        };

        async function handleEmailSubmit(newEmail) {
            if (validate_email(newEmail)) {
              await registerEmail(newEmail, accessToken);
              setModalIsOpen(false);
              shouldRefresh.current = true;
                setFormInvalid(false);
              forceUpdateCallback(); // This shouldn't be needed but is?
              history.go(0);
              return true;
          } else {
            setFormInvalid(true);
            return false;
          }
        }


    // Just refreshed, don't need to refresh again
    if (shouldRefresh) {
        shouldRefresh.current = false;
    }

    async function handleCreateAlert() {
      if (contact.account_id && contact.id && limitThreshold.value && accessToken) {
        await createHealthAlert(
            contact.account_id,
            contact.id,
            alertMessage,
            limitThreshold.value,
            accessToken
        );
        // Reditect to home page
        history.push(PAGE_PATHS.HOME);
      } else {
          setFormInvalid(true);
      }
    }

    function handleNewLimitThreshold(newName) {
        setLimitThreshold(
            BORROW_LIMIT_TARGETS.filter((obj) => obj.name === newName)[0]
        );
    }

    return (
        <>
            <EmailModal
                isOpen={modalIsOpen}
                submitEmail={handleEmailSubmit}
                modalClosed={() => setModalIsOpen(false)}
                formInvalid={formInvalid}
            />
            <Column gap={theme.spacing.sm}>
                <BackNavigator to={PAGE_PATHS.CHOOSE_ALERT}>
                    Change alert type
                </BackNavigator>
                <PageTitle>Create account health alert</PageTitle>
                <Row align="top">
                    <Card flex={2.0}>
                        <Column align="left" gap={theme.spacing.lg}>
                            <Typography.displayS>
                                1. Select borrow limit target
                            </Typography.displayS>
                            <Dropdown
                                items={BORROW_LIMIT_TARGETS.map(
                                    (obj) => obj.name
                                )}
                                valueUpdateCallback={(newName) =>
                                    handleNewLimitThreshold(newName)
                                }
                            ></Dropdown>
                            <Break />
                            <Typography.displayS>
                                2. Add message to the alert
                            </Typography.displayS>
                            <Card variant="secondary">
                                <TextInput
                                    value={alertMessage}
                                    type="text"
                                    placeholder="Enter text here..."
                                    onChange={(event) =>
                                        setAlertMessage(event.target.value)
                                    }
                                />
                            </Card>
                            { formInvalid &&
          <Typography.caption>  There is a problem with the form information </Typography.caption> }
                            {!isLoggedIn ? (
                                <Button variant="secondary" onClick={login}>
                                    Connect Wallet
                                </Button>
                            ) : !contactMethodConnected ? (
                                <>
                                    <Button
                                        onClick={() => setModalIsOpen(true)}
                                    >
                                        Connect email
                                    </Button>
                                    <Button variant="disabled">
                                        Create Alert
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={handleCreateAlert}>
                                    Create Alert
                                </Button>
                            )}
                        </Column>
                    </Card>
                    <InfoCard src={healthSvg} title="Summary" flex={1}>
                        <ul>
                            <li>
                                When your borrow limit reaches{" "}
                                {limitThreshold.name}
                            </li>
                            <li>You'll recieve an alert every 1 day</li>
                        </ul>
                    </InfoCard>
                </Row>
            </Column>
        </>
    );
}
