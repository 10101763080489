export async function getPriceAlerts(accessToken) {
    //console.log("Calling getPriceAlerts");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/price_rules`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${accessToken}`,
            },
            method: "GET",
        }
    );

    const priceAlerts = await response.json();

    return priceAlerts;
}

// Example return data
// Array(3)
// 0: {id: "20962470-03b4-11ec-8524-13b94c9f37fa", token_blockchain: "eth", token_name: "eth", special_message: "asdasdsad", sign: -1, …}
// 1: {id: "20967290-03b4-11ec-8524-13b94c9f37fa", token_blockchain: "eth", token_name: "eth", special_message: "asdasdsad", sign: -1, …}
// 2: {id: "7ac8cf60-03b4-11ec-8524-13b94c9f37fa", token_blockchain: "eth", token_name: "ETH", special_message: "sdf", sign: -1, …}

export async function createPriceAlert(
    accountId,
    contactId,
    tokenName,
    triggerPrice,
    sign,
    specialMessage,
    accessToken
) {
    //console.log("Calling createPriceAlert");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/price_rules/new`,
        {
            body: JSON.stringify({
                account_id: accountId,
                contact_id: contactId,
                token_blockchain: "eth",
                token_name: tokenName,
                special_message: specialMessage,
                sign: sign,
                trigger_price: triggerPrice,
                email_notification_frequency: 1,
                phone_notification_frequency: 0,
            }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        }
    );

    if (!response.ok) {
        const error = await response.text();
        console.log("Error:" + error);
        return null;
    }

    const alert = await response.json();

    return alert;
}

export async function removePriceAlert(targetId, accessToken) {
    console.log("Calling removePriceAlerts");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/price_rules/`,
        {
            body: JSON.stringify({ price_rule_id: targetId }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "DELETE",
        }
    );

    if (!response.ok) {
        const error = await response.text();
        console.log("Error:" + error);
        return null;
    }
}
