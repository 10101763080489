
import React  from "react";
import { useTheme } from "styled-components";

import { Typography } from "theme";
import { ExternalLink, Logo } from "components/shared";
import Card from "components/Card";
import Column from "components/Column";
import Row from "components/Row";
import { URLS } from "constants.js";
import warningSvg from "assets/warning.svg";
import stakeSignalLogo from "assets/StakeSignalLogo.svg"

export default function MobileSplash({ forceUpdateCallback }) {
    const theme = useTheme();

    return (
      <>
      <Logo src={stakeSignalLogo}   size="50px" />
                <Column gap={theme.spacing.lg}>
          <Card variant="question">
              <Logo src={warningSvg} />
              <Typography.displayL color={theme.color.statusWarning}>
      Welcome to Stake Signal! This site is not yet ready for a mobile experience. Please try again with a computer or larger screen device.</Typography.displayL> </Card>

              <Card variant="question">
                  <Column align="left" gap={theme.spacing.sm}>
                      <Typography.heading>
                          What is Stake Signal?
                      </Typography.heading>
                      <Typography.body>
                          Stake Signal allows you to receive customized alerts
                          about potential liquidation threats to your account on the Compound
                          protocol. You can opt-in to receive notifications via
                          Email or SMS.
                      </Typography.body>
                      <Row>
                          <Logo src={warningSvg} />
                          <Typography.body color={theme.color.statusWarning}>
                              This is an alpha release of Stake Signal supported
                              by a{" "}
                              <ExternalLink
                                  href={URLS.COMPOUND_GRANTS}
                                  variant="warning"
                              >
                                  Compound Grant.
                              </ExternalLink> Use of this website is for informational purposes only. This website does not guarantee safety from losses, liquidations, or other risks.
                          </Typography.body>
                      </Row>
                  </Column>
              </Card>
          </Column>
      </>
    );
}
