export async function getTokenPrices() {
    //console.log("Calling getTokenPrices");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/tokens`,
        {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        }
    );
    const prices = await response.json();

    return prices;
}
