
import styled from "styled-components";

const TextInput = styled.input`
    background-color: transparent;
    color: ${({ theme, color }) => color ?? theme.color.text1};
    background-color: ${({ theme }) => theme.color.bg1};
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: ${({ theme }) => theme.radius.lg};
    width: 100%;
    height: ${({ height }) => height ?? "56px"};
    padding: ${({ padding, theme }) => padding ?? theme.spacing.lg};
    margin: 0;
    box-shadow: ${({ theme }) => theme.shadow.card};
    row-gap: 8px;
    overflow: visible;
    font-family: Inter;
    &:focus {
        outline: none;
    }
`;

export default TextInput;
