import jwtDecode from 'jwt-decode';
import { useGlobalStore } from "data/store";
import { getAccount } from "data/requests";
import { useAccessToken } from "utils/hooks/useAccessToken";
import { STORE_KEYS } from "constants.js";
import { logout } from "utils/logout";
import { useHistory } from "react-router-dom";

export function useAccount(shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const accessToken = useAccessToken();
    const key = STORE_KEYS.ACCOUNT;
    const accountData = store[key];
    const history = useHistory();
    const  id = ( accessToken ? jwtDecode(accessToken).payload.id : null );


    async function checkForData() {
        // Decode JWT to make sure payload id matches self
        if (accountData && id && accountData.id != id ) {
            logout();
            history.go(0);
        }

        // Fetch the data if it hasn't been fetched already:
        if ((!accountData || shouldRefresh) && accessToken) {
            const data = await getAccount(accessToken);
            updateStore(key, data);
        }
    }

    try {
      checkForData();
    } catch {
      logout();
      history.go(0);
    }

    return accountData;
}
