import { checkResponse } from "utils";

export async function getContact(accessToken) {
    //console.log("Calling getContact");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/me`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${accessToken}`,
            },
        }
    );

    if (await checkResponse(response)) {
        const contact = await response.json();

        return contact;
    } else {
        return null;
    }
}

export async function registerEmail(email, accessToken) {
    console.log("Calling registerEmail");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/register`,
        {
            body: JSON.stringify({ email }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        }
    );

    if (await checkResponse(response)) {
        const contact = await response.json();
        return contact;
    } else {
        return null;
    }
}

export async function removeEmail(contactId, accessToken) {
    console.log("Calling removeEmail");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/clear_email`,
        {
            body: JSON.stringify({ contact_id: contactId }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
        }
    );

    await checkResponse(response);
}

export async function registerPhone(country_code, phone, accessToken) {
    console.log("Calling registerPhone");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/register_sms`,
        {
            body: JSON.stringify({ country_code, phone }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        }
    );

    if (await checkResponse(response)) {
        const contact = await response.json();

        return contact;
    } else {
        return null;
    }
}

export async function verifyPhone(contactId, verify_code, accessToken) {
    console.log("Calling verifyPhone");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/verify_sms`,
        {
            body: JSON.stringify({ contactId, verify_code }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
        }
    );

    await checkResponse(response);
}

export async function removePhone(contactId, accessToken) {
    console.log("Calling removePhone");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/clear_sms`,
        {
            body: JSON.stringify({ contact_id: contactId }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
        }
    );

    await checkResponse(response);
}
