import { useGlobalStore } from "data/store";
import { getTokenPrices } from "data/requests";
import { STORE_KEYS } from "constants.js";

export function useTokens(shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const key = STORE_KEYS.TOKEN_PRICE;
    const tokenPriceData = store[key];

    async function checkForData() {
        // Fetch the data if it hasn't been fetched already:
        if (!tokenPriceData || shouldRefresh) {
            const data = await getTokenPrices();
            updateStore(key, data);
        }
    }

    checkForData();

    return tokenPriceData;
}

export function useTokenPrice(tokenName, shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const key = STORE_KEYS.TOKEN_PRICE;
    const tokenPriceData = store[key];

    async function checkForData() {
        // Fetch the data if it hasn't been fetched already:
        if (!tokenPriceData || shouldRefresh) {
            const data = await getTokenPrices();
            updateStore(key, data);
        }
    }

    checkForData();

    if (!tokenPriceData) {
        return null;
    } else {
        const coinPrice = tokenPriceData.filter(
            (obj) => obj.name === tokenName
        );
        if (coinPrice.length !== 0) {
            return coinPrice[0].current_price_usd;
        } else {
            return null;
        }
    }
}

// Example return data from useTokens
// Array(17) [ {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, … ]
// 0: Object { name: "AAVE", current_price_usd: 405.3318, updated_at: "2021-08-11T08:05:00.247Z" }
// 1: Object { name: "BAT", current_price_usd: 0.743681, updated_at: "2021-08-11T08:05:00.154Z" }
// 2: Object { name: "BTC", current_price_usd: 46364.297, updated_at: "2021-08-11T08:05:00.116Z" }
// 3: Object { name: "COMP", current_price_usd: 488.71774, updated_at: "2021-08-11T08:05:00.130Z" }
// 4: Object { name: "DAI", current_price_usd: 1.001, updated_at: "2021-08-11T08:05:00.179Z" }
// 5: Object { name: "ETH", current_price_usd: 3233.196, updated_at: "2021-08-11T08:05:00.098Z" }
// 6: Object { name: "LINK", current_price_usd: 25.823648, updated_at: "2021-08-11T08:05:00.142Z" }
// 7: Object { name: "MKR", current_price_usd: 3471.1174, updated_at: "2021-08-11T08:05:00.221Z" }
// 8: Object { name: "REP", current_price_usd: 28.560947, updated_at: "2021-08-11T08:05:00.212Z" }
// 9: Object { name: "SAI", current_price_usd: 17.08744, updated_at: "2021-08-11T08:05:00.202Z" }
// 10: Object { name: "SUSHI", current_price_usd: 11.266031, updated_at: "2021-08-11T08:05:00.256Z" }
// 11: Object { name: "TUSD", current_price_usd: 1, updated_at: "2021-07-02T03:16:39.080Z" }
// 12: Object { name: "UNI", current_price_usd: 30.06, updated_at: "2021-08-11T08:05:00.193Z" }
// 13: Object { name: "USDC", current_price_usd: 1, updated_at: "2021-07-02T03:16:27.437Z" }
// 14: Object { name: "USDT", current_price_usd: 1, updated_at: "2021-07-02T03:16:32.434Z" }
// 15: Object { name: "YFI", current_price_usd: 35820.137, updated_at: "2021-08-11T08:05:00.265Z" }
// 16: Object { name: "ZRX", current_price_usd: 0.983026, updated_at: "2021-08-11T08:05:00.169Z" }
