import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Redirect } from "react-router-dom";

import { Typography } from "theme";
import Card from "components/Card";
import Column from "components/Column";
import Row from "components/Row";
import { SectionTitle, ExternalLink, Logo } from "components/shared";
import Button from "components/Button";
import { URLS } from "constants.js";
import warningSvg from "assets/warning.svg";

import { login } from "data/requests";
import { useIsLoggedIn } from "utils/hooks";

export default function Login({ forceUpdateCallback }) {
    const theme = useTheme();
    const isLoggedIn = useIsLoggedIn();

    if (isLoggedIn) {
        console.log("redirecting...");
        return <Redirect to={"/"} />;
    }

    async function handleLogin() {
        await login();
        forceUpdateCallback(); // Rerender and isLoggedIn will be true
    }

    return (
        <Column gap={theme.spacing.lg}>
            <Card variant="question">
                <Column align="left" gap={theme.spacing.sm}>
                    <Typography.heading>
                        What is Stake Signal?
                    </Typography.heading>
                    <Typography.body>
                        Stake Signal allows you to receive customized alerts
                        about potential liquidation threats to your account on the Compound
                        protocol. You can opt-in to receive notifications via
                        Email or SMS.
                    </Typography.body>
                    <Row>
                        <Logo src={warningSvg} />
                        <Typography.body color={theme.color.statusWarning}>
                            This is an alpha release of Stake Signal supported
                            by a{" "}
                            <ExternalLink
                                href={URLS.COMPOUND_GRANTS}
                                variant="warning"
                            >
                                Compound Grant.
                            </ExternalLink> Use of this website is for informational purposes only. This website does not guarantee safety from losses, liquidations, or other risks.
                        </Typography.body>
                    </Row>
                </Column>
            </Card>
            <SectionTitle title="Your Alerts">Your Alerts</SectionTitle>

            <Card padding={theme.spacing.xl + " 0"}>
                <Column gap={theme.spacing.sm}>
                    <Typography.displayS>
                        Add and Manage Alerts
                    </Typography.displayS>
                    <Typography.body color={theme.color.text2}>
                        Connect a wallet that is lending or borrowing
                        cryptocurrency with Compound.
                    </Typography.body>
                    <Button variant="secondary" onClick={handleLogin}>
                        Connect Wallet
                    </Button>
                </Column>
            </Card>
        </Column>
    );
}
