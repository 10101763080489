import styled, { css, useTheme } from "styled-components";
import { Typography } from "theme";
import { Link } from "react-router-dom";

import Row from "components/Row";

const StyledTitle = styled.div`
    width: ${({ width }) => width ?? "100%"};
    text-align: left;
    padding-top: 20px;

    ${({ variant }) =>
        variant === "page" &&
        css`
            padding-bottom: 30px;
            padding-top: 0px;
        `}
`;

export function SectionTitle({ width, children }) {
    return (
        <StyledTitle width={width}>
            <Typography.displayM>{children}</Typography.displayM>
        </StyledTitle>
    );
}

export function PageTitle({ width, children }) {
    return (
        <StyledTitle width={width} variant="page">
            <Typography.displayXL>{children}</Typography.displayXL>
        </StyledTitle>
    );
}

const StyledExternalLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.color.text2};
    :hover {
        color: ${({ theme }) => theme.color.primary1};
    }

    ${({ variant }) =>
        variant === "inline" &&
        css`
            display: inline;
            color: ${({ theme }) => theme.color.primary1};
            :hover {
                color: ${({ theme }) => theme.color.text2};
            }
        `}

    ${({ variant }) =>
        variant === "warning" &&
        css`
            display: inline;
            color: ${({ theme }) => theme.color.statusWarning};
            :hover {
                opacity: 0.8;
                color: ${({ theme }) => theme.color.statusWarning};
            }
            text-decoration: underline;
        `}
`;

export function ExternalLink({ href, variant, children }) {
    return (
        <StyledExternalLink target="_blank" href={href} variant={variant}>
            {children}
        </StyledExternalLink>
    );
}

// React router Link with style
export const InternalLink = styled(Link)`
    width: ${({ width }) => width ?? "auto"};
    text-decoration: none;
    color: ${({ theme, active }) =>
        active ? theme.color.primary1 : theme.color.text2};

    :hover {
        cursor: pointer;
        opacity: ${({ active }) => (active ? 1.0 : 0.8)};
    }
`;

export const Logo = styled.img`
    vertical-align: middle;
    width: ${({ size, freeWidth }) => (freeWidth ? size ?? "24px" : "auto")};
    height: ${({ size }) => size ?? "24px"};
    border-radius: ${({ borderRadius, size }) =>
        borderRadius ?? "calc(" + size + "/ 2)"};
    margin-right: ${({ theme, marginRight }) => marginRight ?? 0};
`;

export function BackNavigator({ to, children }) {
    const theme = useTheme();

    return (
        <Row>
            <InternalLink to={to}>
                <Typography.body color={theme.color.text2}>
                    ← {children}
                </Typography.body>
            </InternalLink>
        </Row>
    );
}

export const ProgressMarker = styled.div`
    height: 8px;
    width: 56px;
    background-color: red;

    background-color: ${({ theme, active }) =>
        active ? theme.color.primary1 : theme.color.bg2};
`;

export const Break = styled.div`
    height: ${({ theme }) => theme.border.thickness};
    background-color: ${({ theme }) => theme.color.bg2};
    width: 100%;
    margin: ${({ theme, gap }) => (gap ? gap + " 0" : theme.spacing.sm + " 0")};
`;
