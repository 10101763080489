import React from "react";
import styled, {
    css,
    createGlobalStyle,
    ThemeProvider,
} from "styled-components";

// Media queries
export const mediaQuerySizes = {
    extraSmall: 575,
    small: 960,
    large: 1280,
};

const mediaQueries = Object.keys(mediaQuerySizes).reduce((acc, key) => {
    acc[key] = (...args) => css`
        @media (max-width: ${mediaQuerySizes[key]}px) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});

// General theme
const theme = () => ({
    color: {
        white: "#FFFFFF",
        black: "#000000",

        bg0: "#121212",
        bg1: "#1D1D1D",
        bg2: "#2D2D2D",
        bg3: "#929292",

        text1: "#FFFFFF",
        text2: "#A8A7AD",
        text3: "#4E4857",

        primary1: "#27C482",

        statusError: "#FC0C4A",
        statusWarning: "#F2C94C",
        statusInfo: "#2D9CD8",

        alertDown: "#2D9CD8",
        alertUp: "#2D9CD8",
        health: "#FD8800",

        border1: "#272B31",
        border2: "#313131",

        linkExternal: "#808994",
    },

    border: {
        thickness: "1px",
    },

    radius: {
        sm: "8px",
        md: "12px",
        lg: "16px",
    },

    mediaWidth: mediaQueries,

    spacing: {
        none: "none",
        xxs: "4px",
        xs: "8px",
        sm: "12px",
        md: "16px",
        lg: "24px",
        xl: "40px",
        xxl: "64px",
    },

    shadow: {
        none: "none",
        card: "0px 4px 20px rgba(0, 0, 0, 0.25)",
        primary: "0px 0px 8px #00D87C, 0px 4px 20px rgba(0, 0, 0, 0.25)",
        error: "0px 0px 8px #FC0C4A, 0px 4px 20px rgba(0, 0, 0, 0.25)",
    },
});

// Typography theme
const StyledText = styled.div`
    font-family: ${({ family }) => family};
    color: ${({ color, theme }) => color ?? theme.color["text1"]};
    font-size: ${({ fontSize }) => fontSize}px;
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: ${({ lineHeight, useDefaultLineHeight }) =>
        useDefaultLineHeight ? "auto" : lineHeight + "px"};
    text-align: ${({ align }) => align ?? "left"};
`;

export const Typography = {
    displayXL(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={42}
                fontWeight={600}
                lineHeight={60}
                {...props}
            />
        );
    },
    displayL(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={28}
                fontWeight={600}
                lineHeight={30}
                {...props}
            />
        );
    },
    displayM(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={26}
                fontWeight={600}
                lineHeight={30}
                {...props}
            />
        );
    },
    displayS(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={18}
                fontWeight={400}
                lineHeight={26}
                {...props}
            />
        );
    },
    heading(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={16}
                fontWeight={600}
                lineHeight={24}
                {...props}
            />
        );
    },
    subheading(props) {
        return (
            <StyledText
                family="Space Grotesk"
                fontSize={14}
                fontWeight={400}
                lineHeight={16}
                {...props}
            />
        );
    },
    body(props) {
        return (
            <StyledText
                family="Inter"
                fontSize={16}
                fontWeight={400}
                color={"text1"}
                lineHeight={24}
                {...props}
            />
        );
    },
    caption(props) {
        return (
            <StyledText
                family="Inter"
                fontSize={14}
                fontWeight={400}
                lineHeight={20}
                {...props}
            />
        );
    },
};

// Everything that is child of theme, gets theme as its props, so it can be used everywhere
export default function Theme({ children }) {
    return <ThemeProvider theme={theme()}>{children}</ThemeProvider>;
}

// Fonts are imported in index.html
export const GlobalStyle = createGlobalStyle`
	html, div {
		font-family: 'Inter', sans-serif;
	}
	html, body, #root {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 120vh;
		font-weight: 400;
		color: ${({ theme }) => theme.color.text1};
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		background:
            radial-gradient(107.1% 100% at 50% 100%, rgba(1, 188, 108, 0.3) 0%, rgba(0, 0, 0, 0) 100%), #1D1D1D;
		background-color: ${({ theme }) => theme.color.bg0};
		background-repeat: no-repeat;
	}
	* {
		box-sizing: border-box;
	}

	.hidden {
		opacity: 0;
	}
	a {
		color: ${({ theme }) => theme.color.linkInternal}
	}

.align-right {
   flex: 0 0 50%;
   display: flex;
   justify-content: flex-end;
}



    ul {
        margin: 0;
    }

    li {
        text-align: left;
        padding: 5px 0;
    }

    .input {
        background: none;
        border: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]{
        -moz-appearance: textfield;
    }
`;
