import React, { useState } from "react";
import styled from "styled-components";

import Card from "components/Card";
import Row from "components/Row";
import Column from "components/Column";
import { Logo } from "components/shared";

import dropdownSvg from "assets/dropdown.svg";

const Container = styled.div`
    position: relative;
`;

const Base = styled.div`
    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const ListContainer = styled.div`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    left: 0%;
    top: calc(100% + ${({ theme }) => theme.spacing.xxs});
    width: 100%;
    height: 220px;
    overflow: auto;
    z-index: 999;
`;

const ListItem = styled(Row)`
    min-height: 40px;
    padding: ${({ theme }) => theme.spacing.md} 0;
    :hover {
        opacity: 0.8;
        cursor: pointer;
        background-color: ${({ theme }) => theme.color.bg2};
    }
`;

export default function Dropdown({ items, imgSrc, valueUpdateCallback }) {
    const [value, setValue] = useState(items[0]);
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState({ left: 0, top: 0 });

    function handleToggle(event) {
        setOpen(!open);
        const left = event.target.offsetLeft;
        const top = event.target.offsetTop + event.target.offsetHeight;
        setPosition({ left: left, top: top });
    }

    function handleSelectItem(item) {
        setValue(item);
        valueUpdateCallback(item);
        setOpen(false);
    }

    return (
        <Container>
            <Base onClick={(event) => handleToggle(event)}>
                <Card variant="secondary" width="100%" active={open}>
                    <Row justify="space-between">
                        <Row>
                            {imgSrc ? (
                                <Logo src={imgSrc[items.indexOf(value)]} />
                            ) : null}
                            {value}
                        </Row>
                        <Logo src={dropdownSvg} size="10px" />
                    </Row>
                </Card>
            </Base>
            <ListContainer
                isOpen={open}
                left={position.left}
                top={position.top}
            >
                <Card variant="secondary">
                    <Column overflow="auto" gap={0}>
                        {items.map((item, i) => {
                            return (
                                <ListItem
                                    onClick={() => handleSelectItem(item)}
                                    key={item}
                                >
                                    {imgSrc ? <Logo src={imgSrc[i]} /> : null}
                                    {item}
                                </ListItem>
                            );
                        })}
                    </Column>
                </Card>
            </ListContainer>
        </Container>
    );
}
