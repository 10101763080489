import React, { useState, useRef } from "react";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Typography } from "theme";
import Card, { InfoCard } from "components/Card";
import Row from "components/Row";
import Column from "components/Column";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { PageTitle, BackNavigator, Break } from "components/shared";
import Button from "components/Button";
import { PAGE_PATHS, COINS, PRICE_ALERT_TYPE } from "constants.js";
import EmailModal from "components/Modal/EmailModal";
import { formatNumber } from "utils";

import { registerEmail } from "data/requests";
import { login, createPriceAlert } from "data/requests";
import { useContact, useTokenPrice } from "data/hooks";
import { useAccessToken, useIsLoggedIn } from "utils/hooks";

const NumberInput = styled.input`
    border: none;
    background-color: transparent;
    color: ${({ theme, color }) => color ?? theme.color.text1};
    font-size: 16px;
    width: 100%;
    resize: ${({ resize }) => resize ?? "vertical"};
    font-family: Inter;
    &:focus {
        outline: none;
    }
`;

export default function CreateHealthAlert({ forceUpdateCallback }) {
    const [selectedToken, setSelectedToken] = useState(COINS[0]); // ETH default
    const [priceTarget, setPriceTarget] = useState(""); // ETH default
    const [alertMessage, setAlertMessage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [priceAlertType, setPriceAlertType] = useState(PRICE_ALERT_TYPE[1]); // Above
    const [formInvalid, setFormInvalid] = useState(false);

    const shouldRefresh = useRef(false);
    const theme = useTheme();
    const isLoggedIn = useIsLoggedIn();
    const accessToken = useAccessToken();
    const contact = useContact();
    const history = useHistory();
    const tokenPrice = useTokenPrice(selectedToken.name);

    const contactMethodConnected = contact && contact.email_redacted !== null;

            function validate_email(email){
              const re = /[^@]+@[^@]+\.[^@]+/;
              return re.test(email);
            };

            async function handleEmailSubmit(newEmail) {
                if (validate_email(newEmail)) {
                  await registerEmail(newEmail, accessToken);
                  setModalIsOpen(false);
                  shouldRefresh.current = true;
                  setFormInvalid(false);
                  forceUpdateCallback(); // This shouldn't be needed but is?
                  history.go(0);
                  return true;
              } else {
                setFormInvalid(true);
                return false;
              }
            }

    function handleNewSelectedToken(tokenName) {
        setSelectedToken(
            COINS.filter((coinInfo) => coinInfo.name === tokenName)[0]
        );
    }

    function handleNewPriceAlertType(newTypeName) {
        setPriceAlertType(
            PRICE_ALERT_TYPE.filter((obj) => obj.name === newTypeName)[0]
        );
    }

    // Just refreshed, don't need to refresh again
    if (shouldRefresh) {
        shouldRefresh.current = false;
    }

    async function handleCreateAlert() {
      if (contact.account_id && contact.id && selectedToken.name && priceTarget && priceAlertType.value && accessToken) {
        createPriceAlert(
            contact.account_id,
            contact.id,
            selectedToken.name,
            priceTarget,
            priceAlertType.value,
            alertMessage,
            accessToken
        );
        forceUpdateCallback();
        history.push(PAGE_PATHS.HOME);
      } else {
          setFormInvalid(true);
      }
    }

    return (
        <>
            <EmailModal
                isOpen={modalIsOpen}
                submitEmail={handleEmailSubmit}
                modalClosed={() => setModalIsOpen(false)}
                formInvalid={formInvalid}
            />
            <Column gap={theme.spacing.sm}>
                <BackNavigator to={PAGE_PATHS.CHOOSE_ALERT}>
                    Change alert type
                </BackNavigator>
                <PageTitle>Create price alert</PageTitle>
                <Row align="top">
                    <Card flex={2.0}>
                        <Column align="left" gap={theme.spacing.lg}>
                            <Typography.displayS>
                                1. Set price target
                            </Typography.displayS>
                            <Card variant="secondary">
                                <Row>
                                    <Row>
                                        $
                                        <NumberInput
                                            type="number"
                                            placeholder="Enter value..."
                                            value={priceTarget}
                                            onChange={(event) =>
                                                setPriceTarget(
                                                    event.target.value
                                                )
                                            }
                                        ></NumberInput>
                                    </Row>
                                    USD
                                </Row>
                            </Card>
                            <Dropdown
                                items={COINS.map((coinInfo) => coinInfo.name)}
                                imgSrc={COINS.map(
                                    (coinInfo) => coinInfo.imgSrc
                                )}
                                valueUpdateCallback={(tokenName) => {
                                    handleNewSelectedToken(tokenName);
                                }}
                            />
                            <Typography.caption color={theme.color.text2}>
                                Current {selectedToken.name} price:{" "}
                                <b>${formatNumber(tokenPrice)}</b>
                            </Typography.caption>
                            <Break />
                            <Typography.displayS>
                                2. Warn when price is
                            </Typography.displayS>
                            <Dropdown
                                items={PRICE_ALERT_TYPE.map(
                                    (type) => type.name
                                )}
                                valueUpdateCallback={(newTypeName) => {
                                    handleNewPriceAlertType(newTypeName);
                                }}
                            />
                            <Break />
                            <Typography.displayS>
                                3. Add message to the alert
                            </Typography.displayS>
                            <Card variant="secondary">
                                <TextInput
                                    value={alertMessage}
                                    type="text"
                                    placeholder="Enter text here..."
                                    onChange={(event) =>
                                        setAlertMessage(event.target.value)
                                    }
                                />
                            </Card>
                            { formInvalid &&
          <Typography.caption>  There is a problem with the form information </Typography.caption> }
                            {!isLoggedIn ? (
                                <Button variant="secondary" onClick={login}>
                                    Connect Wallet
                                </Button>
                            ) : !contactMethodConnected ? (
                                <>
                                    <Button
                                        onClick={() => setModalIsOpen(true)}
                                    >
                                        Connect email
                                    </Button>
                                    <Button variant="disabled">
                                        Create Alert
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={handleCreateAlert}>
                                    Create Alert
                                </Button>
                            )}
                        </Column>
                    </Card>
                    <InfoCard
                        src={selectedToken.imgSrc}
                        title="Summary"
                        flex={1}
                    >
                        <ul>
                            <li>
                                When the price of {selectedToken.name} is
                                 { " " + priceAlertType.name.toLowerCase() + " "} of ${priceTarget}.
                            </li>
                            <li>You'll recieve this alert every 1 day.</li>
                            <li>Via email</li>
                        </ul>
                    </InfoCard>
                </Row>
            </Column>
        </>
    );
}
