import React, { useState, useRef } from "react";
import { useTheme } from "styled-components";
import { Redirect } from "react-router-dom";

//import Alert from '@material-ui/lab/Alert';
import { Typography } from "theme";
import Card from "components/Card";
import Column from "components/Column";
import Row from "components/Row";
import { PageTitle, SectionTitle, Logo } from "components/shared";
import Button from "components/Button";
import { PAGE_PATHS } from "constants.js";
import EmailModal from "components/Modal/EmailModal";
import DeleteModal from "components/Delete";

import { registerEmail, removeEmail } from "data/requests";

import lockSvg from "assets/lock.svg";
//import trashSvg from "assets/trash.svg";

import { useContact } from "data/hooks";
import { useIsLoggedIn, useAccessToken, useForceUpdate } from "utils/hooks";

export default function Settings() {
    const shouldRefresh = useRef(false);
    const contact = useContact(shouldRefresh.current);
    const isLoggedIn = useIsLoggedIn();
    const accessToken = useAccessToken();
    const theme = useTheme();
    const forceUpdate = useForceUpdate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);

    if (!isLoggedIn) {
        return <Redirect to={PAGE_PATHS.LOGIN} />;
    }

    const email = contact ? contact.email_redacted : null;

    function validate_email(email){
      const re = /[^@]+@[^@]+\.[^@]+/;
      return re.test(email);
    };

    async function handleEmailSubmit(newEmail) {
        if (validate_email(newEmail)) {
          await registerEmail(newEmail, accessToken);
          setModalIsOpen(false);
          shouldRefresh.current = true;
            setFormInvalid(false);
          forceUpdate(); // This shouldn't be needed but is?
          return true;
      } else {
        setFormInvalid(true);
        return false;
      }
    }

    async function handleEmailRemove() {
        await removeEmail(contact.id, accessToken);
        shouldRefresh.current = true;
        forceUpdate(); // This shouldn't be needed but is?
    }

    // Just refreshed, don't need to refresh again
    if (shouldRefresh) {
        shouldRefresh.current = false;
    }

    return (
        <>
            <EmailModal
                isOpen={modalIsOpen}
                submitEmail={handleEmailSubmit}
                modalClosed={() => setModalIsOpen(false)}
                formInvalid={formInvalid}
            />
            <Column>

                <PageTitle>Settings</PageTitle>
                <SectionTitle>Notification contact methods</SectionTitle>
                <Card>
                    <Column>
                        {email ? (
                            <Card variant="secondary">
                                <Row>
                                    <Column align="left">
                                        <Typography.subheading>
                                            EMAIL
                                        </Typography.subheading>
                                        <Typography.caption>
                                            {email}
                                            { contact && contact.email_redacted && contact.validated_email != 1 ? " (Unverified. Please click confirmation link sent to this address) " : "" }

                                        </Typography.caption>
                                    </Column>

                                <DeleteModal handleAction={() => handleEmailRemove() } />
                                </Row>
                            </Card>
                        ) : (
                            <Card variant="secondary">
                                <Row>
                                    <Column align="left">
                                        <Typography.subheading>
                                            EMAIL
                                        </Typography.subheading>
                                        <Typography.caption>
                                            No email connected
                                        </Typography.caption>
                                    </Column>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setModalIsOpen(true)}
                                    >
                                        Connect email
                                    </Button>
                                </Row>
                            </Card>
                        )}
                        <Card>
                            <Row>
                                <Logo src={lockSvg} />
                                <Typography.caption color={theme.color.text2}>
                                    Your data is yours. We secure it via
                                    encryption and don't sell or share it. We
                                    only collect the information necessary to
                                    provide our services. You may unsubscribe at
                                    any time by disconnecting your method of
                                    contact here.
                                </Typography.caption>
                            </Row>
                        </Card>
                    </Column>
                </Card>
            </Column>
        </>
    );
}
