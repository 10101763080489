import React from "react";
import styled from "styled-components";
import { HashRouter, Route, Switch } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import Login from "pages/Login";
import Home from "pages/Home";
import Settings from "pages/Settings";
import MobileSplash from "pages/MobileSplash";
import ChooseAlert from "pages/ChooseAlert";
import CreatePriceAlert from "pages/CreatePriceAlert";
import CreateHealthAlert from "pages/CreateHealthAlert";

import { PAGE_PATHS } from "./constants";
import { useForceUpdate } from "utils/hooks";

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px;
    padding-top: 50px;
    column-gap: ${({ theme }) => theme.spacing.md};
    row-gap: ${({ theme }) => theme.spacing.md};
    > * {
        max-width: 800px;
    }
    ${({ theme }) => theme.mediaWidth.small`
		padding-left: 15px;
		padding-right: 15px;
		column-gap: ${({ theme }) => theme.spacing.xs};
		row-gap: ${({ theme }) => theme.spacing.xs};
	`}
`;

const MediaShow = styled.div`
  display: none;

  /* use a media query to filter small devices */
  @media screen and (max-device-width:480px) {
    /* show the popup */
    display: block;
}
`;

const MediaBlock = styled.div`
  display: block;
  /* use a media query to filter small devices */
  @media screen and (max-device-width:480px) {
      display: none;
}
`;

export default function App() {
    // Used when the backend gets updated (http req) to let the app know to refetch and rerender
    // data from the hook (ex. new alert added)
    const forceUpdate = useForceUpdate();

    return (
        <HashRouter>
            <MediaShow>
              <StyledBody>
                <MobileSplash />
              </StyledBody>
            </MediaShow>
            <MediaBlock>
            <Header forceUpdateCallback={forceUpdate} />
            <StyledBody>
                <Switch>
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.LOGIN}
                        component={() => (
                            <Login forceUpdateCallback={forceUpdate} />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.HOME}
                        component={() => (
                            <Home forceUpdateCallback={forceUpdate} />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.SETTINGS}
                        component={() => (
                            <Settings forceUpdateCallback={forceUpdate} />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.CHOOSE_ALERT}
                        component={() => <ChooseAlert />}
                    />
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.CREATE_PRICE_ALERT}
                        component={() => (
                            <CreatePriceAlert
                                forceUpdateCallback={forceUpdate}
                            />
                        )}
                    />
                    <Route
                        exact
                        strict
                        path={PAGE_PATHS.CREATE_HEALTH_ALERT}
                        component={() => (
                            <CreateHealthAlert
                                forceUpdateCallback={forceUpdate}
                            />
                        )}
                    />
                </Switch>
                <Footer />
            </StyledBody>
            </MediaBlock>
        </HashRouter>
    );
}
