import jwtDecode from 'jwt-decode';
import { useAccessToken } from "utils/hooks/useAccessToken";
import { useGlobalStore } from "data/store";
import { LS_KEY } from "constants.js";

export function useIsLoggedIn() {
    const  { clearStore } = useGlobalStore();
    const accessToken = useAccessToken();
    if (accessToken !== null) {
      //Decode access token
      var decoded = jwtDecode(accessToken);
      var dateNow = new Date();
      var expiration = Date(decoded.exp*1000);
    //  console.log("exp", expiration)
    //  console.log("date", dateNow)

      if( expiration < dateNow.getTime()) {
         //If it's expired nuke it and start over.
            localStorage.removeItem(LS_KEY);
            //clearStore();
            return false;
        } else {
          return true;
        }
      } else {
      return false
    }
}
