import React from "react";
import styled, { useTheme, css } from "styled-components";

import { Typography } from "theme";
import Row from "components/Row";
import { Logo } from "components/shared";
import Column from "components/Column";

import warningLogo from "assets/warning.svg";

const Card = styled.div`
    display: flex;
    flex-direction: ${({ column }) => (column ? "column" : "row")};
    border-radius: ${({ theme }) => theme.radius.lg};
    background-color: ${({ theme }) => theme.color.bg0};
    height: ${({ height }) => height ?? "100%"};
    width: ${({ width }) => width ?? "100%"};
    padding: ${({ padding, theme }) => padding ?? theme.spacing.lg};
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin: 0;
    box-shadow: ${({ theme }) => theme.shadow.card};
    row-gap: 8px;
    overflow: visible;
    ${({ theme }) => theme.mediaWidth.small`
		padding: ${({ theme }) => theme.spacing.md};
	`}
    flex: ${({ flex }) => flex ?? 1};

    ${({ variant }) =>
        variant === "question" &&
        css`
            background-color: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            border: none;
        `}

    ${({ variant, theme }) =>
        variant === "warning" &&
        css`
            background-color: ${theme.color.bg1};
            backdrop-filter: blur(10px);
            border: 2px solid ${theme.color.statusWarning};
        `}

    ${({ variant, theme }) =>
        variant === "secondary" &&
        css`
            background-color: ${theme.color.bg1};
            border: none;
        `}

    ${({ active, theme }) =>
        active &&
        css`
            border: 1px solid ${theme.color.primary1};
        `}
`;

export default Card;

export function WarningCard({ children }) {
    return (
        <Card variant="warning">
            <Row>
                <Logo src={warningLogo} />
                <div>{children}</div>
            </Row>
        </Card>
    );
}

export function InfoCard({ title, src, children }) {
    const theme = useTheme();

    return (
        <Card>
            <Column justify="center">
                <Logo size="48px" src={src} />
                <Typography.displayS>{title}</Typography.displayS>
                <Typography.body color={theme.color.text2} align="center">
                    {children}
                </Typography.body>
            </Column>
        </Card>
    );
}
