import aaveSvg from "assets/coins/AAVE.svg";
import batSvg from "assets/coins/BAT.svg";
import compSvg from "assets/coins/COMP.svg";
import daiSvg from "assets/coins/DAI.svg";
import ethSvg from "assets/coins/ETH.svg";
import linkSvg from "assets/coins/LINK.svg";
import mkrSvg from "assets/coins/MKR.svg";
import sushiSvg from "assets/coins/SUSHI.svg";
import tusdSvg from "assets/coins/TUSD.svg";
import uniSvg from "assets/coins/UNI.svg";
import usdcSvg from "assets/coins/USDC.svg";
import usdtSvg from "assets/coins/USDT.svg";
import wbtcSvg from "assets/coins/WBTC.svg";
import yfiSvg from "assets/coins/YFI.svg";
import zrxSvg from "assets/coins/ZRX.svg";

import stakeSignalLogo from "assets/StakeSignalLogo.svg"

export const LS_KEY = "stake-signal:auth";

export const COINS = [
    {
        name: "ETH",
        imgSrc: ethSvg,
    },
    {
        name: "BTC",
        imgSrc: wbtcSvg,
    },
    {
        name: "COMP",
        imgSrc: compSvg,
    },
    {
        name: "AAVE",
        imgSrc: aaveSvg,
    },
    {
        name: "BAT",
        imgSrc: batSvg,
    },
    {
        name: "DAI",
        imgSrc: daiSvg,
    },
    {
        name: "LINK",
        imgSrc: linkSvg,
    },
    {
        name: "MKR",
        imgSrc: mkrSvg,
    },
    {
        name: "SUSHI",
        imgSrc: sushiSvg,
    },
    {
        name: "TUSD",
        imgSrc: tusdSvg,
    },
    {
        name: "UNI",
        imgSrc: uniSvg,
    },
    {
        name: "USDC",
        imgSrc: usdcSvg,
    },
    {
        name: "USDT",
        imgSrc: usdtSvg,
    },
    {
        name: "YFI",
        imgSrc: yfiSvg,
    },
    {
        name: "ZRX",
        imgSrc: zrxSvg,
    },
];

export const URLS = {
    COMPOUND_DOCS: "https://compound.finance/docs",
    COMPOUND_FINANCE: "https://compound.finance",
    COMPOUND_GRANTS: "https://twitter.com/compoundgrants",
    GITHUB: "https://github.com/",
};

export const PAGE_PATHS = {
    HOME: "/",
    LOGIN: "/login",
    SETTINGS: "/settings",
    MARKETING: "/marketing",
    CHOOSE_ALERT: "/choose-alert",
    CREATE_PRICE_ALERT: "/create-price-alert",
    CREATE_HEALTH_ALERT: "/create-health-alert",
};

export const ALERT_FREQUENCY = [
    { name: "1 day", value: 1 },
    { name: "2 day", value: 2 },
    { name: "7 day", value: 7 },
];

export const BORROW_LIMIT_TARGETS = [
    { name: "50%", value: "0.50" },
    { name: "55%", value: "0.55" },
    { name: "60%", value: "0.60" },
    { name: "65%", value: "0.65" },
    { name: "70%", value: "0.70" },
    { name: "75%", value: "0.75" },
    { name: "80%", value: "0.80" },
    { name: "85%", value: "0.85" },
    { name: "90%", value: "0.90" },
    { name: "95%", value: "0.95" },
];

// Dictionary keys where data is held within the global store
export const STORE_KEYS = {
    ACCOUNT: "account",
    CONTACT: "contact",
    HEALTH_ALERTS: "healthAlerts",
    PRICE_ALERTS: "priceAlerts",
    TOKEN_PRICE: "tokenPrice",
    API_ERROR: "apiError"
};

export const PRICE_ALERT_TYPE = [
    { name: "Below price target", value: -1 },
    { name: "Above price target", value: 1 },
];
