import { useGlobalStore } from "data/store";
import { getHealthAlerts } from "data/requests";
import { useAccessToken } from "utils/hooks/useAccessToken";
import { STORE_KEYS } from "constants.js";
import { logout } from "utils/logout";
import {  useHistory } from "react-router-dom";

export function useHealthAlerts(shouldRefresh = false) {
    const [store, { updateStore }] = useGlobalStore();
    const accessToken = useAccessToken();
    const key = STORE_KEYS.HEALTH_ALERTS;
    const healthAlertData = store[key];
    const history = useHistory();

    async function checkForData() {
        // Fetch the data if it hasn't been fetched already:
        if ((!healthAlertData || shouldRefresh) && accessToken) {
            const data = await getHealthAlerts(accessToken);
            updateStore(key, data);
        }
    }

        try {
          checkForData();
        } catch {
          logout();
          history.go(0);
        }


    return healthAlertData;
}
