import { checkResponse } from "utils";

export async function getHealthAlerts(accessToken) {
    //console.log("Calling getHealthAlerts");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/health_rules`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${accessToken}`,
            },
            method: "GET",
        }
    );

    if (await checkResponse(response)) {
        const healthAlerts = await response.json();
        return healthAlerts;
    } else {
        return null;
    }
}

// Example return
// Array(2)
// 0: {id: "4da244a0-0380-11ec-8524-13b94c9f37fa", special_message: "test", account_health_threshold: 0.5, email_notification_frequency: 1, phone_notification_frequency: 0}
// 1: {id: "bf5c0cd0-0384-11ec-8524-13b94c9f37fa", special_message: "jhg", account_health_threshold: 0.5, email_notification_frequency: 1, phone_notification_frequency: 0}

export async function createHealthAlert(
    accountId,
    contactId,
    specialMessage,
    threshold,
    accessToken
) {
    //console.log("Calling createHealthAlerts");

    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/health_rules/new`,
        {
            body: JSON.stringify({
                account_id: accountId,
                contact_id: contactId,
                special_message: specialMessage,
                account_health_threshold: threshold,
                email_notification_frequency: 1,
                phone_notification_frequency: 0,
            }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        }
    );

    if (await checkResponse(response)) {
        const alert = await response.json();

        return alert;
    } else {
        return null;
    }
}

export async function removeHealthAlert(targetId, accessToken) {
    //console.log("Calling removeHealthAlerts");
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/health_rules/`,
        {
            body: JSON.stringify({ health_rule_id: targetId }),
            headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
            },
            method: "DELETE",
        }
    );

    await checkResponse(response);
}
