import React, { useRef } from "react";
import styled from "styled-components";

import Column from "components/Column";

import { useClickOutside } from "utils/hooks/useClickOutside";

const Container = styled.div`
    positon: relative;
`;

const Background = styled.div`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 998;
    background-color: ${({ theme }) => theme.color.bg2};
    opacity: 0.5;
`;

const StyledModal = styled.div`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    width: 420px;
    max-height: 500px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
`;

export default function Modal({ isOpen, modelClosed, children }) {
    const node = useRef();
    useClickOutside(node, isOpen ? () => modelClosed() : undefined);

    // Disable scrolling when modal is isOpen
    if (isOpen) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "scroll";
    }

    return (
        <Container>
            <Background isOpen={isOpen} />
            <StyledModal isOpen={isOpen} ref={node}>
                <Column justify="center" height="100%">
                    {children}
                </Column>
            </StyledModal>
        </Container>
    );
}
