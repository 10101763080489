import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  Logo } from "components/shared";

import trashSvg from "assets/trash.svg";


export const Delete = styled.div`
   &&& {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
     }
`;

export default function DeleteModal({handleAction}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


    return (
      <Delete>
              <Logo src={trashSvg}  onClick={handleClickOpen} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This will delete your information.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  No
                </Button>
                <Button onClick={handleAction} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
        </Delete>
    );
}
