import React, { StrictMode } from "react";
import { config as dotEnvConfig } from "dotenv";
import ReactDOM from "react-dom";

import App from "App";
import Theme, { GlobalStyle } from "theme";
import GlobalStoreProvider from "data/store";

// Load ENV variables
dotEnvConfig({
    path:
        process.env.NODE_ENV === "production"
            ? ".env.production"
            : ".env.development",
});

// Render app
ReactDOM.render(
    <StrictMode>
        <GlobalStoreProvider>
            <Theme>
                <GlobalStyle />
                <App />
            </Theme>
        </GlobalStoreProvider>
    </StrictMode>,
    document.getElementById("root")
);
