import React from "react";
import { useTheme } from "styled-components";

import { InfoCard } from "components/Card";
import Column from "components/Column";
import Row from "components/Row";
import { PageTitle, BackNavigator, InternalLink } from "components/shared";
import { PAGE_PATHS } from "constants.js";

import healthSvg from "assets/health.svg";
import priceAlertSvg from "assets/price-alert.svg";

export default function ChooseAlert() {
    const theme = useTheme();

    return (
        <Column gap={theme.spacing.lg}>
            <BackNavigator to={PAGE_PATHS.HOME}>Home</BackNavigator>
            <PageTitle>Choose alert type</PageTitle>
            <Row gap={theme.spacing.lg}>
                <InternalLink to={PAGE_PATHS.CREATE_HEALTH_ALERT} width="100%">
                    <InfoCard src={healthSvg} title="Account Health Alert">
                        Get notified when your borrow limit goes above or below
                        a specified percentage.
                    </InfoCard>
                </InternalLink>
                <InternalLink to={PAGE_PATHS.CREATE_PRICE_ALERT} width="100%">
                    <InfoCard src={priceAlertSvg} title="Price Alert" freeWidth>
                        Get notified when the price of an asset on Compound
                        reaches a specified price target.
                    </InfoCard>
                </InternalLink>
            </Row>
        </Column>
    );
}
